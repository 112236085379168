export default {
  en: {
    id: "ID",
    new: "New",
    new_contacts: "New Contact",
    my_contacts: "My Contact",
    add: "Add",
    export: "Export",
    submit: "Submit",
    add_success: "Successfully added contacts",
    search_placeholder:
      "Search by name, project, company, phone, wechat, qq number, personal link, source identifier, Wechat Unionid",
    clear: "Clear",
    filter: "Filter",
    upload: "Upload",
    cancel: "Cancel",
    tasks: "Tasks",
    reset: "Reset",
    close: "Close",
    show: "Show",
    back: "Back",
    delete: "Delete",
    select_date: "Select Date",
    new_filter: "New Filter",
    apply: "Apply",
    download: "Download",
    fuzzy_assign: "Download template",
    download_template: "Download Template",
    autocomplete: "Auto Complete",
    upload_success: "Upload Success",
    upload_fail: "Upload Failed",
    download_and_check_fail_reason: "Download and check failed reason",
    name: "Name",
    wechat_unionid: "Wechat Unionid",
    latest_phone_callout_time: "Last Phone Callout Time",
    latest_phone_callout_connected_time: "Last Phone Callout Connected Time",
    latest_change_operator_time: "Latest Change Operator Time",
    existing_data_allocated: "Existing Data Allocated",
    existing_data_activated: "Existing Data Activated",
    qq: "QQ Number",
    lead_status: "Lead Status",
    lead_status_desc:
      "Filling in: The clue information is being completed;Pending Review: Information is complete and awaiting team leader's review;Convertible: The lead is qualified and ready for further conversion.",
    lead_status_options: {
      completion: "Completing",
      review: "To be reviewed",
      convertible: "Convertible",
      waiting_completion: "Waiting Completion",
    },
    category: "Portrait",
    project: "Product/Project/Brand",
    organization: "Company Name",
    organization_desc: "产品/项目所在的公司",
    introduction: "Project Introduction",
    introduction_desc:
      "Establishment time, team situation, business condition, user quantity, etc",
    information: "Contact Profile",
    information_desc:
      "Education, age, work background, career highlights, etc. ",
    financing_information: "Financing Information",
    address_keywords: "Address Keywords",
    address_keywords_desc:
      "For example: USA, China, Beijing, 150 Chengfu Road, Silicon Valley, Suzhou Industrial Park, 335 Pioneer Way, Mountain View, CA, 94041, United States, etc. ",
    url: "Company/Project Link",
    personal_link: "Personal Link",
    url_desc: "Official website, product URL, project homepage, etc",
    field: "Research Direction",
    field_desc:
      "For example: B2B, advanced manufacturing, lithium niobate batteries, energy storage, nuclear fusion, etc. ",
    phone: "Phone",
    phone_2: "Phone 2",
    phone_3: "Phone 3",
    phone_extra_placeholder: "(Include country code for international phone)",
    email: "Email",
    email_2: "Email 2",
    email_3: "Email 3",
    email_4: "Email 4",
    focus_tags: "Focus Tags",
    wechat: "Wechat",
    wechat_added_at: "Wechat Added At",
    personal_link_desc:
      "Education, age, work background, career highlights, etc. ",
    operator_id: "Converter",
    operator_name: "Converter",
    communication: "Communication",
    communication_desc: "Contact status of existing leads",
    application_progress: "Application Progress",
    application_progress_desc: "Latest application progress",
    application_stage: "Application Stage",
    application_stage_desc: "Latest application stage",
    revisit_at: "Latest Follow-up Time",
    submitted_at: "Submitted At",
    willingness_score: "Willingness Rating",
    willingness_score_desc:
      "Refers to the entrepreneur's willingness to fill out forms, regardless of the quality of the entrepreneur. Maximum rating is 5 points.",
    clue_user_id: "Clue User",
    clue_user: "Clue User",
    source: "Source channels",
    s_product: "Source Product",
    s_channel: "Source Channel",
    s_referral: "Source Referral",
    referral_user_id: "Source Referral",
    referral_user: "Source Referral",
    utm_source: "Utm Source",
    utm_campaign: "Utm Campaign",
    utm_content: "Utm Content",
    source_other: "Source Remark",
    source_detail: "Source Detail",
    source_identifier: "Source Identifier",
    source_identifier_desc: "id号或主页链接",
    source_identifier_placeholder: "Source Identifier (id or link)",
    xiaohongshu_identifier_placeholder: "6-15 letters, numbers, underscores",
    xiaohongshu_identifier_message: "Xiaohongshu ID number format is incorrect",
    wechat_identifier_placeholder:
      "6-20 digits, letters, underscore, minus sign",
    wechat_identifier_message: "WeChat ID format is incorrect",
    hackathon_identifier_placeholder:
      "1-99 digits, letters, underscores, minus signs, dots",
    hackathon_identifier_message: "The hackathon id format is incorrect",
    twitter_identifier_placeholder:
      "6-30 characters, numbers, underscores and minus signs",
    twitter_identifier_message: "Twitter id format is incorrect",
    github_identifier_placeholder:
      "1-39 letters, numbers, hyphens and underscores, cannot start with a number",
    github_identifier_message: "github id format is incorrect",
    linkedin_identifier_placeholder:
      "3-100 letters, numbers, underscores, and minus signs. Do not use spaces, symbols, or special characters.",
    linkedin_identifier_message: "Incorrect linkedin id format",
    producthunt_identifier_placeholder:
      "1-99 digits, letters, underscores, minus signs",
    producthunt_identifier_message: "producthunt id format is incorrect",
    jike_identifier_placeholder:
      "1-99 digits, letters, underscores, minus signs",
    jike_identifier_message: "The ID format is incorrect",
    zhihu_identifier_placeholder:
      "1-99 digits, letters, underscore, minus sign",
    zhihu_identifier_message: "The Zhihu ID format is incorrect",
    company_name: "Company Name",
    company_name_desc: "工作所在的企业/机构",
    founder_career: "Founder Career",
    company_category: "Company Category",
    job_title: "Job Title",
    school: "School",
    education: "Education",
    remark: "Lead Introduction",
    contact_group_remark: "Contact Group Remark",
    operator_group_remark: "Operator Group Remark",
    created_at: "Entry Time",
    outbound_entry_at: "Latest Entry Time",
    created_at_desc:
      "The time when the contact lead is submitted to the system",
    outbound_entry_at_desc: "Latest entry time",
    register_at: "Register At",
    created_application_at: "Created Application At",
    events: "Contact Events",
    actions: "Actions",
    ignored: "Ignored",
    ignored_desc:
      'For founders who have already resented or indicated that they will not apply, interns can click on the "Ignored" button to stop bothering founders.;If a founder has failed three consecutive second-round reviews, it will be automatically marked as "Ignored" and interns can not contact him.',
    contact_count: "{count} contacts found",
    name_for_empty: "Name cannot be blank",
    project_for_empty: "Project Name cannot be blank",
    project_or_organization_or_information_for_empty:
      "Fill in at least one item for project / organization",
    phone_not_valid: "Phone is illegal, at least 8 digits",
    add_more_phone: "Add more Phone",
    add_more_email: "Add more Email",
    hide_email_2: "Delete Email 2",
    hide_email_3: "Delete Email 3",
    hide_email_4: "Delete Email 4",
    hide_phone_2: "Delete Phone 2",
    hide_phone_3: "Delete Phone 3",
    contact_for_empty: "Fill in at least one item for phone / wechat / email",
    contact_quickly_for_empty: "Fill in at least one item for phone / email",
    email_not_valid: "Email is illegal",
    personal_link_not_valid: "personal_link is illegal",
    url_not_valid: "Link is illegal",
    valid_fail:
      "The information you filled in is incomplete, please supplement",
    clue_user_for_empty: "Clue User can not be blank",
    source_for_empty: "Source can not be blank",
    source_product_for_empty: "Source product can not be blank",
    source_channel_for_empty: "Source channel can not be blank",
    source_referral_for_empty: "Source referral can not be blank",
    utm_source_for_empty: "Utm source can not be blank",
    source_identifier_for_empty: "Source Identifier can not be blank",
    source_detail_for_empty: "Source Detail can not be blank",
    source_channel_notes_for_empty: "Source channel notes cannot be empty",
    category_for_empty: "Category can not be blank",
    link_invalid: "Link is invalid, please check",
    duplicate_preference_field: "Duplicate Field, Need remove first",
    col_setting: "Set Columns",
    col_setting_tip: "can drag column",
    batch_actions: "Batch Actions",
    rows: "Rows",
    base_info: "Basic Information",
    identity_info: "Identity Information",
    project_info: "Project Information",
    ancillary_info: "Ancillary Information",
    contact_details: "Contact Details (Fill in at least one item)",
    source_info: "Source Information",
    other: "Other",
    last_upadted_at: "Last Updated At",
    upload_contacts: "Upload Contacts",
    upload_tip: "Please read the Instructions for Filling In in the template",
    upload_drag_tip: "Click or drag the file to this area",
    support: "Support",
    records: "records",
    bulk_assign: "Contact Transfer",
    export_effective_communication: "Export Effective Communication",
    add_this_contact: "Add this contact?",
    custmoized_export: "Custmoized Export",
    select_all: "Select All",
    cancel_selected: "Cancel Selected",
    deduplication: "Deduplication",
    deduplication_tasks: "Tasks",
    duplcate_contact_tip:
      "Specific information about repeated contacts is as follows",
    communicate_with_clue_user_tip:
      "Please communicate with the clue user in charge in a timely manner",
    intern_structure: "Intern Structure",
    apply_intent: "Apply Intent",
    urgent: "Is Urgent",
    urgent_description: "Is priority contact required",
    urgent_required: "Is Urgent is required",
    contact_user_id: "Contact Person",
    contact_user: "Contact Person",
    contact_method: "Contact Method",
    contact_at: "Contact At",
    contact_at_description: "Time of first contact attempt",
    successful_contact_at: "Successful Contact At",
    outbound_consolidated_to_wecom: "Outbound Consolidated to Wecom",
    contact_feedback: "Contact Feedback",
    cold_call_user_id: "Cold Call Handler",
    cold_call_user: "Cold Call Handler",
    consolidated_wecom_account: "Consolidated Wecom Account",
    wecom_group_created: "Is WeCom Group Created",
    confirm_meeting_at: "Confirm Meeting At",
    meeting_at: "Meeting At",
    meeting_success: "Is Meeting Success",
    pipelines: {
      statuses: {
        queuing: "Queuing",
        running: "Running",
        failed: "Failed",
      },
    },
    categories: {
      business_started: "Startup began",
      tech_giants_employee: "Company employee",
      professor: "Scholar",
      student: "Student",
      investor: "Investor",
      partner: "Partner",
      colleague: "Colleague",
      others: "Other",
      alumni: "Alumni",
    },
    communications: {
      unaware: "Haven't reached out",
      reaching: "Pending",
      aware: "Average intended",
      success: "High intended",
      finish: "Form completed",
      reaware: "Given up/open now",
      on_hold: "On hold",
      active_pending_meeting: "Active pending meeting",
      lead_meeting_scheduled: "Lead meeting scheduled",
    },
    sources: {
      secondary_networking: "一二度人脉拓新",
      cooperator: "合作方介绍",
      public: "公开信息",
      mp_inbound: "奇绩inbound",
      alumni_fission: "校友裂变",
      outbound: "outbound",
      advertising: "广告投放",
    },
    s_products: {
      ba: "创业营",
      ls: "潜空间",
      ss: "公开课",
      au: "AIU",
      llm: "大模型日报",
      qs: "齐思",
      mbti: "mbti",
      nc: "女创",
      cyzn: "创业指南",
      bj: "大模型笔记",
      bp: "BP生成器",
    },
    s_channels: {
      ib: "inbound",
      ad: "广告投放",
      al: "校友",
      sj: "批量数据",
      ob: "outbound",
      e2e: "E2E",
    },
    utm_sources: {
      ob: "官网",
      ea: "外部活动",
      ep: "外部平台",
      te: "腾讯",
      cd: "工商数据",
      list: "名单",
      google: "谷歌",
      baidu: "百度",
      xhs: "小红书",
      gzh: "公众号",
      gx: "高校",
      kol: "付费kol",
      otd: "一二度人脉",
      pc: "爬虫数据",
      dy: "抖音",
      bili: "B站",
      jike: "即刻",
      xyz: "小宇宙",
      sph: "视频号",
      fs: "飞书",
      vx: "微信私域",
      else: "其他渠道",
    },
    source_details: {
      hackathon: "Hackathon",
      xiaohongshu: "小红书",
      wechat: "微信",
      twitter: "Twitter",
      github: "Github",
      linkedin: "LinkedIn",
      jike: "即刻",
      zhiqian: "知潜",
      producthunt: "Product Hunt",
      zhihu: "知乎",
      competition_roadshow: "大赛/路演",
      paper: "论文",
      school: "学校官网",
      other: "other",
    },
    apply_intents: {
      willing_to_apply: "Willing to apply",
      long_term_follow_up: "Long-term follow-up",
      explicit_rejection: "Explicit rejection",
      unclear_intention: "Unclear intention",
      no_contact_established: "No contact established",
    },
    urgents: {
      urgent: "Urgent",
      not_urgent: "Not Urgent",
    },
    contact_methods: {
      cold_call: "ColdCall",
      wecom: "WeCom",
      wechat: "WeChat",
      sdr_direct_create_group: "SDR Direct Create Group",
      other: "Other",
    },
    wecom_group_createds: {
      created: "Created Group",
      not_created: "Not Created Group",
    },
    meeting_successes: {
      second_time_meeting: "Second Time Meeting",
      success: "Success",
      fail: "Fail",
    },
    existing_data_allocateds:{
      allocated_e3c: 'Allocated-E3C',
      allocated_stock: 'Allocated-Stock',
      not_allocated: 'Not Allocated'
    },
    existing_data_activateds: {
      priority_activated: "Priority Activated",
      activated: "Activated",
    },
    contact_event: {
      add_event_placeholder:
        "Add a communication record（Entrepreneur demand feedback、GCPT）",
      name: "Contact Events",
      title: "Title",
      content: "Content",
      origin_operator: "Origin Operator",
      registration_time: "Registration Time",
      open_time_of_application: "Open Time Of Application",
      submit_time: "Submit Time",
      updated_by: "Updated By",
      mark_ignore: "Mark Ignore",
    },
    application: {
      application_status: "Apply Status",
      username: "applicant",
      batch_name: "Batch",
      stage: "Review Rounds",
      stage_status: "Review Result",
      product_name: "Product Name",
      company_name: "Company Name",
      founder_info: "Founders",
      product_introduction: "Product Introduction",
      positive: "Positives",
      negative: "Negatives",
    },
    application_stages: {
      first_review: "First Review",
      second_review: "Second Review",
      interview: "Interview",
    },
    founder_careeres: {
      full_time_entrepreneur: "Full-time Entrepreneur",
      employed__research_institution: "Employed - Research Institution",
      employed__corporate: "Employed - Corporate",
      employed__freelancer: "Employed - Freelancer",
      on_campus__phd: "On Campus - PhD Student",
      on_campus__master: "On Campus - Master Student",
      on_campus__undergraduate_or_high_school: "On Campus - Undergraduate or Below",
      pending_employment: "Pending Employment",
    },
    company_categories: {
      enterprise: "Enterprise",
      b2c: "B2C",
      b2b: "B2B",
      community: "Community",
      security: "Security",
      robotics: "Robotics",
      international_market: "International Market",
      marketplace: "Marketplace",
      financial_services: "Financial Services",
      artificial_intelligence: "Artificial Intelligence",
      media: "Media",
      developer_tools: "Developer Tools",
      government: "Government",
      chip: "Chip",
      education: "Education",
      energy: "Energy",
      transportation: "Transportation",
      agriculture: "Agriculture",
      biotech: "Biotech",
      healthcare: "Healthcare",
      science: "Science",
      hardware: "Hardware",
      drones: "Drones",
      augmented_reality: "Augmented Reality",
      entertainment: "Entertainment",
      sensors: "Sensors",
      virtual_reality: "Virtual Reality",
      crypto_blockchain: "Crypto / Blockchain",
      travel: "Travel",
      consumer_goods: "Consumer Goods",
      sport: "Sport",
      estate_service: "Estate Service",
      advanced_manufacturing: "Advanced Manufacturing",
      open_source: "Open Source",
      aerospace: "Aerospace",
      metaverse: "Metaverse",
      sustainability: "Sustainability",
      other: "Other",
    },
  },
  cn: {
    id: "ID",
    new: "添加",
    new_contacts: "添加人脉",
    claim_contacts: "认领人脉",
    claim: "认领",
    claim_success: "认领成功",
    origin_clue_user: "原线索人",
    origin_contact_user: "原触达人",
    origin_operator_user: "原转化人",
    my_contacts: "我的人脉",
    export: "导出",
    add: "新增",
    submit: "提交",
    add_success: "新增人脉成功",
    search_placeholder:
      "搜索姓名、项目、公司、手机号、微信号、QQ号、邮箱、个人主页、渠道标识、微信Unionid",
    clear: "清除",
    filter: "筛选",
    upload: "上传",
    cancel: "取消",
    tasks: "任务列表",
    reset: "重置",
    close: "关闭",
    back: "返回",
    show: "查看",
    delete: "删除",
    select_date: "选择日期",
    new_filter: "新增筛选条件",
    apply: "应用",
    download: "下载",
    fuzzy_assign: "模糊转移",
    download_template: "下载模版",
    autocomplete: "自动补全",
    upload_success: "上传成功",
    upload_fail: "上传失败",
    download_and_check_fail_reason: "点击下载并查看失败原因",
    name: "姓名",
    wechat_unionid: "微信Unionid",
    latest_phone_callout_time: "上次呼叫时间",
    latest_phone_callout_connected_time: "上次接通时间",
    latest_change_operator_time: "最新转人脉时间",
    existing_data_allocated: "存量是否分配",
    existing_data_activated: "存量激活状态",
    qq: "QQ号",
    lead_status: "线索状态",
    lead_status_desc:
      "补全中: 线索信息正在补充完整;待审核: 信息补充完整，待组长审核;可转化: 线索合格，开始后续转化;待修补:线索不合格，待修补",
    lead_status_options: {
      completion: "补全中",
      review: "待审核",
      convertible: "可转化",
      waiting_completion: "待修补",
    },
    category: "画像",
    project: "产品/项目/品牌名字",
    organization: "公司注册名",
    organization_desc: "产品/项目所在的公司",
    introduction: "项目简介",
    introduction_desc: "成立时间、团队情况、经营状况、用户数量等",
    information: "联系人简介",
    information_desc: "学历、年龄、工作背景、履历亮点等",
    financing_information: "融资信息",
    address_keywords: "地址关键词",
    address_keywords_desc:
      "例如：美国、中国、北京、成府路 150 号、硅谷、苏州工业园区、335 Pioneer Way, Mountain View, CA, 94041, United States 等",
    url: "公司官网/项目链接",
    personal_link: "个人主页",
    url_desc: "官网、产品网址、项目主页等",
    field: "研究方向",
    field_desc: "例如：B2B，先进制造，铌酸锂电池，储能，核聚变等",
    phone: "手机号",
    phone_2: "手机号2",
    phone_3: "手机号3",
    email: "邮箱",
    email_2: "邮箱2",
    email_3: "邮箱3",
    email_4: "邮箱4",
    wechat: "微信号",
    wechat_added_at: "加微信时间",
    phone_extra_placeholder: "(国际号码请包含国家号)",
    personal_link_desc: "领英、学术主页、Github 主页等",
    operator_id: "转化人",
    operator_name: "转化人",
    communication: "接触状态",
    communication_desc: "存量线索的接触状态",
    application_progress: "申请进度",
    application_progress_desc: "最近一次申请的申请进度",
    application_stage: "审阅轮次",
    application_stage_desc: "最近一次申请的审阅轮次",
    revisit_at: "最新跟进时间",
    submitted_at: "提交时间",
    willingness_score: "意愿评分",
    willingness_score_desc: "指的是线索的填表意愿，与线索质量无关，5 分最高",
    clue_user_id: "线索人",
    clue_user: "线索人",
    source: "来源渠道",
    s_product: "原始来源产品",
    s_channel: "原始渠道大类",
    s_referral: "原始分享人",
    referral_user_id: "原始分享人",
    referral_user: "原始分享人",
    utm_source: "原始一级渠道",
    utm_campaign: "原始二级渠道",
    utm_content: "原始三级渠道",
    source_other: "来源渠道备注",
    job_title: "工作岗位",
    school: "学校名",
    education: "学历背景",
    source_detail: "渠道详情",
    source_identifier: "渠道标识",
    source_identifier_desc: "id号或主页链接",
    source_identifier_placeholder: "渠道标识 (id号或主页链接)",
    xiaohongshu_identifier_placeholder: "6-15位字母、数字、下划线",
    xiaohongshu_identifier_message: "小红书号格式不正确",
    wechat_identifier_placeholder: "6-20位数字、字母、下划线、减号",
    wechat_identifier_message: "微信号格式不正确",
    hackathon_identifier_placeholder: "1-99位数字、字母、下划线、减号、点",
    hackathon_identifier_message: "hackathon id格式不正确",
    twitter_identifier_placeholder: "6-30位英文、数字、下划线和减号",
    twitter_identifier_message: "twitter id格式不正确",
    github_identifier_placeholder:
      "1-39位字母、数字、连字符和下划线，不能以数字开头",
    github_identifier_message: "github id格式不正确",
    linkedin_identifier_placeholder:
      "3-100位字母、数字、下划线和减号, 请勿使用空格、符号或特殊字符",
    linkedin_identifier_message: "linkedin id格式不正确",
    producthunt_identifier_placeholder: "1-99位数字、字母、下划线、减号",
    producthunt_identifier_message: "producthunt id格式不正确",
    jike_identifier_placeholder: "1-99位数字、字母、下划线、减号",
    jike_identifier_message: "即刻id格式不正确",
    zhihu_identifier_placeholder: "1-99位数字、字母、下划线、减号",
    zhihu_identifier_message: "知乎id格式不正确",
    company_name: "企业/机构名",
    company_name_desc: "工作所在的企业/机构",
    focus_tags: "重点标签",
    remark: "线索介绍",
    contact_group_remark: "触达组备注",
    operator_group_remark: "转化组备注",
    created_at: "录入时间",
    created_at_desc: "人脉线索提交至系统的时间",
    outbound_entry_at: "最新激活时间",
    outbound_entry_at_desc: "人脉线索提交的最新激活时间",
    register_at: "注册时间",
    created_application_at: "开表时间",
    events: "人脉动态",
    actions: "操作",
    ignored: "暂不联系",
    ignored_desc:
      "对于已有反感或表示不会申请的创业者，实习生可以点击“暂不联系”按钮，不再打扰创业者。;连续三次二轮审阅未通过的人脉，会自动标注“暂不联系”，不可主动联系。",
    contact_count: "找到{count}个人脉",
    name_for_empty: "姓名不能为空",
    project_for_empty: "项目不能为空",
    project_or_organization_for_empty: "'名称'或'企业/机构名'至少填一项",
    phone_not_valid: "手机号码不合法，至少8位",
    add_more_phone: "添加其他手机号",
    add_more_email: "添加其他邮箱",
    hide_email_2: "删除邮箱2",
    hide_email_3: "删除邮箱3",
    hide_email_4: "删除邮箱4",
    hide_phone_2: "删除手机号2",
    hide_phone_3: "删除手机号3",
    contact_for_empty: "手机号/微信号/邮箱至少填一项",
    contact_quickly_for_empty: "手机号/邮箱至少填一项",
    email_not_valid: "邮箱不合法",
    personal_link_not_valid: "个人主页链接不合法",
    url_not_valid: "链接不合法",
    valid_fail: "您填写的信息不完整，请补充",
    clue_user_for_empty: "线索人不能为空",
    source_for_empty: "来源渠道不能为空",
    source_product_for_empty: "原始来源产品不能为空",
    source_channel_for_empty: "原始来源大类不能为空",
    source_referral_for_empty: "原始分享人不能为空",
    utm_source_for_empty: "原始一级渠道不能为空",
    source_identifier_for_empty: "渠道标识不能为空",
    source_detail_for_empty: "渠道详情不能为空",
    source_channel_notes_for_empty: "来源渠道备注不能为空",
    category_for_empty: "画像不能为空",
    founder_career: "职位阶段",
    company_category: "公司业务类别",
    origin_user_for_empty: "原负责人不能为空",
    link_invalid: "无效链接, 请联系您的奇绩负责人获取正确链接",
    duplicate_preference_field: "您添加了重复联系方式，请先移除",
    col_setting: "设置列",
    col_setting_tip: "通过拖拽设置列顺序",
    batch_actions: "批量操作",
    rows: "行数",
    base_info: "基础信息",
    identity_info: "身份信息",
    project_info: "项目信息",
    ancillary_info: "辅助工作信息",
    contact_details: "联系方式 (至少填一项)",
    source_info: "渠道信息",
    other: "其他",
    last_upadted_at: "最后更新于",
    upload_contacts: "上传人脉",
    upload_tip: "请阅读模板中的【填写须知】",
    upload_drag_tip: "点击或拖拽文件到此区域",
    support: "支持",
    records: "条记录",
    bulk_assign: "人脉转移",
    export_effective_communication: "导出有效沟通",
    add_this_contact: "是否新增该人脉？",
    custmoized_export: "自定义导出",
    select_all: "全选",
    cancel_selected: "取消已选",
    deduplication: "人脉去重",
    deduplication_tasks: "任务列表",
    intern_structure: "人员架构",
    apply_intent: "申请意愿",
    urgent: "是否紧急",
    urgent_description: "是否需要优先触达",
    urgent_required: "是否紧急为必填",
    contact_user_id: "触达人",
    contact_user: "触达人",
    contact_method: "触达方式",
    contact_at: "触达时间",
    contact_at_description: "第一次尝试触达的时间",
    successful_contact_at: "触达成功时间",
    outbound_consolidated_to_wecom: "outbound 沉淀个微号",
    contact_feedback: "触达反馈",
    cold_call_user_id: "Cold Call 处理人",
    cold_call_user: "Cold Call 处理人",
    consolidated_wecom_account: "沉淀企微号",
    wecom_group_created: "企微是否拉群",
    confirm_meeting_at: "成功约会时间",
    meeting_at: "开会时间",
    meeting_success: "是否成功开会",
    pipelines: {
      statuses: {
        queuing: "队列中",
        running: "处理中",
        failed: "失败",
      },
    },
    categories: {
      business_started: "已经创业",
      tech_giants_employee: "在职员工",
      professor: "科研学者",
      student: "学生",
      investor: "投资人",
      partner: "合作方",
      colleague: "奇绩同事",
      others: "其他",
      alumni: "奇绩校友",
    },
    communications: {
      blank: "空",
      unaware: "待接触",
      reaching: "触达过程中",
      aware: "沟通说服中",
      success: "说服催填",
      finish: "说服填完",
      reaware: "放弃/开放接触",
      on_hold: "暂不联系",
      active_pending_meeting: "激活待约会",
      lead_meeting_scheduled: "线索已约会",
    },
    sources: {
      secondary_networking: "一二度人脉拓新",
      cooperator: "合作方介绍",
      public: "公开信息",
      mp_inbound: "奇绩inbound",
      alumni_fission: "校友裂变",
      outbound: "outbound",
      advertising: "广告投放",
    },
    s_products: {
      ba: "创业营",
      ls: "潜空间",
      ss: "公开课",
      au: "AIU",
      llm: "大模型日报",
      qs: "齐思",
      mbti: "mbti",
      nc: "女创",
      cyzn: "创业指南",
      bj: "大模型笔记",
      bp: "BP生成器",
    },
    s_channels: {
      ib: "inbound",
      ad: "广告投放",
      al: "校友",
      sj: "批量数据",
      ob: "outbound",
      e2e: "E2E",
    },
    utm_sources: {
      ob: "官网",
      ea: "外部活动",
      ep: "外部平台",
      te: "腾讯",
      cd: "工商数据",
      list: "名单",
      google: "谷歌",
      baidu: "百度",
      xhs: "小红书",
      gzh: "公众号",
      gx: "高校",
      kol: "付费kol",
      otd: "一二度人脉",
      pc: "爬虫数据",
      dy: "抖音",
      bili: "B站",
      jike: "即刻",
      xyz: "小宇宙",
      sph: "视频号",
      fs: "飞书",
      vx: "微信私域",
      else: "其他渠道",
    },
    source_details: {
      hackathon: "Hackathon",
      xiaohongshu: "小红书",
      wechat: "微信",
      twitter: "Twitter",
      github: "Github",
      linkedin: "LinkedIn",
      jike: "即刻",
      zhiqian: "知潜",
      producthunt: "Product Hunt",
      zhihu: "知乎",
      competition_roadshow: "大赛/路演",
      paper: "论文",
      school: "学校官网",
      other: "其他",
    },
    apply_intents: {
      willing_to_apply: "愿意申请",
      long_term_follow_up: "需要长期跟进",
      explicit_rejection: "明确拒绝",
      unclear_intention: "意向不明确",
      no_contact_established: "未联系上/未建立沟通",
    },
    urgents: {
      urgent: "紧急",
      not_urgent: "不紧急",
    },
    contact_methods: {
      cold_call: "ColdCall",
      wecom: "企微",
      wechat: "个微",
      sdr_direct_create_group: "SDR直接拉群",
      other: "其他",
    },
    wecom_group_createds: {
      created: "已拉群",
      not_created: "未拉群",
    },
    meeting_successes: {
      second_time_meeting: "二次会议",
      success: "成功",
      fail: "失败",
    },
    existing_data_allocateds:{
      allocated_e3c: '已分配-E3C',
      allocated_stock: '已分配-存量组',
      not_allocated: '未分配'
    },
    existing_data_activateds: {
      priority_activated: "优先激活",
      activated: "已激活",
    },
    contact_event: {
      add_event_placeholder: "添加沟通记录（创业者需求反馈、GCPT）",
      name: "人脉动态",
      title: "标题",
      content: "内容",
      origin_operator: "原负责人",
      registration_time: "注册时间",
      open_time_of_application: "开表时间",
      submit_time: "提交时间",
      updated_by: "操作者",
      mark_ignore: "暂不联系",
    },
    application: {
      application_status: "申请状态",
      username: "申请者",
      batch_name: "批次",
      stage: "审阅轮次",
      stage_status: "审阅结果",
      product_name: "产品名称",
      company_name: "公司名称",
      founder_info: "创始人信息",
      product_introduction: "一句话介绍",
      positive: "项目的优点",
      negative: "项目的缺点",
    },
    application_stages: {
      first_review: "第一轮",
      second_review: "第二轮",
      interview: "面试",
    },
    founder_careeres: {
      full_time_entrepreneur: "全职创业",
      employed__research_institution: "在职-研究机构",
      employed__corporate: "在职-公司企业",
      employed__freelancer: "在职-自由职业",
      on_campus__phd: "在读-博士在读",
      on_campus__master: "在读-硕士在读",
      on_campus__undergraduate_or_high_school: "在读-本科及以下在读",
      pending_employment: "待业",
    },
    company_categories: {
      enterprise: "企业服务",
      b2c: "B2C",
      b2b: "B2B",
      community: "社区",
      security: "安全",
      robotics: "机器人",
      international_market: "国际市场",
      marketplace: "市场平台",
      financial_services: "金融服务",
      artificial_intelligence: "人工智能",
      media: "媒体",
      developer_tools: "开发者工具",
      government: "政府",
      chip: "芯片",
      education: "教育",
      energy: "能源",
      transportation: "运输",
      agriculture: "农业",
      biotech: "生物技术",
      healthcare: "医疗",
      science: "科学研究",
      hardware: "硬件",
      drones: "无人机",
      augmented_reality: "增强现实",
      entertainment: "娱乐",
      sensors: "传感器",
      virtual_reality: "虚拟现实",
      crypto_blockchain: "区块链",
      travel: "旅游",
      consumer_goods: "消费",
      sport: "体育运动",
      estate_service: "地产服务",
      advanced_manufacturing: "先进制造",
      open_source: "开源",
      aerospace: "航空航天",
      metaverse: "元宇宙",
      sustainability: "碳中和",
      other: "其他",
    },
  },
};
